import { files } from "src/api";

import { ProductDB, ProductWithPrice } from "src/db-types";

import { RootState } from "../store";

import { FilteredProductsMap } from "./types";

export const getAllProducts = (state: RootState): ProductDB[] => state.products.products;
export const getBatteries = (state: RootState): ProductDB[] => state.products.batteries;
export const getHeatpumps = (state: RootState): ProductDB[] => state.products.heatpumps;
export const getCars = (state: RootState): ProductDB[] => state.products.cars;
export const getSolarPanels = (state: RootState): ProductDB[] => state.products.solarPanels;
export const getWallboxes = (state: RootState): ProductDB[] => state.products.wallboxes;
export const getInverters = (state: RootState): ProductDB[] => state.products.inverters;
export const getEms = (state: RootState): ProductDB[] => state.products.EMS;

export const getAdditionalSoftwares = (state: RootState): ProductDB[] =>
  state.products.additionalSoftware;
export const getAdditionalHardwares = (state: RootState): ProductDB[] =>
  state.products.additionalHardware;

export const getAllFilteredProducts = (state: RootState): FilteredProductsMap =>
  state.products.filteredProducts;
export const getFilteredBatteries = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.batteries;
export const getFilteredHeatpumps = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.heatpumps;
export const getFilteredSolarPanels = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.solarPanels;
export const getFilteredWallboxes = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.wallboxes;
export const getFilteredInverters = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.inverters;
export const getFilteredAdditionalSoftwares = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.additionalSoftware;

export const getFilteredAdditionalHardwares = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.additionalHardware;

export const getFilteredEms = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.EMS;

export const getAllProductObjFiles = (state: RootState): files[] => state.products.productObjFiles;
export const getFilteredAdditionalSoftware = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.additionalSoftware;

export const getFilteredAdditionalHardware = (state: RootState): ProductWithPrice[] =>
  state.products.filteredProducts.additionalHardware;
